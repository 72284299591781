import request from './request';
import mixin from '@/mixin.js';

export default {
  appendSearch(url, opts, search) {
    // console.log('opts:', opts);
    opts ||= {};
    let qp = [];
    if (search?.length > 0) {
      qp.push(`search=${search}`);
    }
    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }
    if (opts.filters) {
      for (let filter in opts.filters) {
        let f = opts.filters[filter];
        qp.push(`${f.field}${f.operation}${f.value}`);
      }
    }
    let sort = [];
    if (opts.sortBy) {
      for (let i = 0; i < opts.sortBy.length; i++) {
        let sign = '';
        if (i < opts.sortDesc.length && opts.sortDesc[i]) {
          sign += '-';
        }
        let field = opts.sortBy[i];
        sort.push(sign + field);
      }
      if (sort.length > 0) {
        qp.push(`ordering=${sort.join(',')}`);
      }
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  async start(plan_item_id, comments, operation) {
    let data = {
      comments: comments,
      operation: operation
    };

    return await request.request(
      `/api/production/${plan_item_id}/start/`,
      'put',
      data
    );
  },

  async stop(plan_item_id, comments, net, gross, qcReport) {
    let data = {
      comments: comments,
      quantity_net: net,
      quantity_gross: gross,
      qc_report: qcReport
    };

    return await request.request(
      `/api/production/${plan_item_id}/stop/`,
      'put',
      data
    );
  },

  async onHold(plan_item_id, comments) {
    let data = {
      comments: comments
    };

    return await request.request(
      `/api/production/${plan_item_id}/on_hold/`,
      'put',
      data
    );
  },

  async resume(plan_item_id, comments) {
    let data = {
      comments: comments
    };

    return await request.request(
      `/api/production/${plan_item_id}/resume/`,
      'put',
      data
    );
  },

  async finish(plan_item_id, comments) {
    let data = {
      comments: comments
    };

    return await request.request(
      `/api/production/${plan_item_id}/finish/`,
      'put',
      data
    );
  },

  async getQualityIssues(opts, search) {
    console.log('getQualityIssues');
    const url = this.appendSearch('/api/qc/', opts, search);
    const result = await request.request(url, 'get', {}, {});
    console.log('result: ', result);

    let items = result.data?.results;

    items = items.map((i) => {
      return {
        ...i,
        created_at: mixin.methods.dateFromISO8601(i.created_at),
        read_at: mixin.methods.dateFromISO8601(i.read_at)
      };
    });

    return [items, result.data.count];
  },

  async QCMarkRead(item) {
    let id = item.id;
    const url = `/api/qc/${id}/mark_read/`;
    console.log('PUT ', url);

    const result = await request.request(url, 'put', {}, {});

    console.log('result: ', result);
    return result.data;
  }
};
