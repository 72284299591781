import request from './request';
import mixin from '@/mixin.js';

export default {
  appendSearch(url, opts, search) {
    // console.log('opts:', opts);
    opts ||= {};
    let qp = [];
    if (search?.length > 0) {
      qp.push(`search=${search}`);
    }
    if (opts.page) {
      qp.push(`page=${opts.page}`);
    }
    if (opts.itemsPerPage) {
      qp.push(`size=${opts.itemsPerPage}`);
    }
    if (opts.filters) {
      for (let filter in opts.filters) {
        let f = opts.filters[filter];
        qp.push(`${f.field}${f.operation}${f.value}`);
      }
    }
    let sort = [];
    if (opts.sortBy) {
      for (let i = 0; i < opts.sortBy.length; i++) {
        let sign = '';
        if (i < opts.sortDesc.length && opts.sortDesc[i]) {
          sign += '-';
        }
        let field = opts.sortBy[i];
        sort.push(sign + field);
      }
      if (sort.length > 0) {
        qp.push(`ordering=${sort.join(',')}`);
      }
    }

    let qps = qp.join('&');
    if (qps.length > 0) {
      url += '?' + qps;
    }

    return url;
  },

  async getProductionLog(opts, search) {
    let url = `/api/productionlog/`;
    url = this.appendSearch(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});

    let items = rsp.data?.results;
    items = items.map((i) => {
      return {
        ...i,
        created_at: mixin.methods.dateFromISO8601(i.created_at),
        read_at: mixin.methods.dateFromISO8601(i.read_at)
      };
    });

    return [items, rsp.data.count];
  },

  async patchLog(item) {
    let id = item.id;
    const url = `/api/productionlog/${id}/`;
    console.log('PATCH ', url);

    const result = await request.request(url, 'patch', item, {});

    console.log('result: ', result);
    return result;
  },

  async productionLogMarkRead(item) {
    let id = item.id;
    const url = `/api/productionlog/${id}/mark_read/`;
    console.log('PUT ', url);

    const result = await request.request(url, 'put', {}, {});

    console.log('result: ', result);
    return result.data;
  },

  async productionLogUpdateQuantity(item) {
    let id = item.id;
    const url = `/api/productionlog/${id}/update_quantity/`;
    console.log('PUT ', url);

    const result = await request.request(url, 'put', item, {});

    console.log('result: ', result);
    return result.data;
  }
};
